import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M2430 3860 c0 -41 -4 -60 -12 -60 -9 0 -9 -3 1 -9 24 -15 38 -2 34
32 l-4 32 20 -24 c12 -13 21 -29 21 -37 0 -8 7 -14 15 -14 18 0 18 2 4 34 -10
20 -9 28 5 41 9 9 16 20 16 25 0 20 -34 40 -66 40 l-34 0 0 -60z m61 34 c10
-13 10 -17 -2 -25 -23 -14 -42 -11 -35 7 3 9 6 20 6 25 0 14 16 11 31 -7z"/>
<path d="M2653 3855 c-1 -19 -8 -49 -16 -65 l-15 -30 38 -11 c38 -12 70 -7 70
9 0 4 -16 6 -35 4 -36 -4 -45 8 -26 37 7 10 13 11 30 2 17 -10 21 -9 21 3 0 8
-10 16 -22 18 -17 2 -22 9 -20 23 5 29 8 30 36 11 31 -20 36 -20 36 -2 0 15
-10 20 -65 30 -29 6 -30 5 -32 -29z"/>
<path d="M2187 3805 c-98 -20 -177 -49 -312 -116 -140 -69 -217 -124 -341
-246 -401 -395 -507 -1003 -264 -1520 118 -253 351 -494 596 -617 110 -55 152
-71 272 -101 254 -65 510 -56 767 25 74 24 259 111 286 136 8 8 19 14 23 14 4
0 12 4 19 10 135 109 217 185 280 258 40 47 129 186 166 260 33 66 91 229 91
256 0 26 -19 18 -26 -11 -25 -103 -123 -312 -188 -402 -58 -81 -67 -93 -156
-181 -125 -126 -250 -212 -400 -275 -36 -15 -80 -33 -98 -41 -18 -8 -93 -26
-165 -40 -122 -24 -146 -26 -307 -21 -228 7 -341 36 -534 133 -143 72 -223
130 -342 249 -204 201 -330 445 -374 725 -15 95 -12 324 5 421 29 167 112 372
208 514 96 141 236 277 381 369 132 84 304 153 446 178 35 6 59 15 57 21 -5
16 -16 16 -90 2z"/>
<path d="M2857 3763 c-23 -52 -24 -59 -9 -70 9 -7 30 -13 48 -13 35 0 50 22
38 54 -3 9 -1 16 5 16 21 0 11 38 -14 54 -13 9 -28 16 -33 16 -5 0 -21 -26
-35 -57z m71 14 c2 -12 -3 -17 -17 -17 -15 0 -21 6 -21 21 0 25 33 22 38 -4z
m-20 -60 c-3 -24 -17 -30 -37 -18 -10 7 -11 13 -2 29 14 26 43 18 39 -11z"/>
<path d="M3051 3684 c-26 -34 -27 -68 -1 -94 43 -43 120 -10 120 51 0 63 -81
92 -119 43z m83 -10 c20 -19 20 -34 0 -62 -18 -26 -56 -29 -74 -7 -11 13 -10
22 3 50 18 38 45 45 71 19z"/>
<path d="M3261 3534 c-33 -44 -34 -47 -20 -73 24 -43 64 -40 110 8 24 25 35
43 28 47 -10 6 -18 -2 -31 -26 -2 -3 -15 -16 -30 -29 -23 -20 -30 -22 -43 -11
-22 19 -18 41 15 80 33 38 37 50 18 50 -7 0 -29 -21 -47 -46z"/>
<path d="M3470 3390 c0 -5 -4 -10 -10 -10 -5 0 -18 -8 -29 -19 -10 -10 -27
-23 -36 -29 -10 -5 -15 -14 -11 -20 4 -7 19 1 38 19 34 31 50 37 43 17 -11
-30 -22 -108 -15 -108 5 0 26 15 49 34 22 19 46 36 53 39 10 3 10 8 2 16 -8 9
-19 4 -44 -19 -18 -17 -34 -29 -36 -27 -2 2 1 19 6 38 13 44 13 79 0 79 -5 0
-10 -4 -10 -10z"/>
<path d="M3620 3203 c0 -5 -23 -21 -50 -36 -28 -16 -50 -33 -50 -38 0 -18 58
-69 78 -69 31 0 72 38 72 67 0 26 -29 83 -42 83 -4 0 -8 -3 -8 -7z m27 -49
c17 -44 -51 -94 -84 -61 -21 21 -12 40 29 60 41 21 47 21 55 1z"/>
<path d="M1730 3090 c-106 -5 -113 -6 -83 -18 57 -24 58 -34 58 -492 0 -467
-1 -476 -66 -497 -28 -9 -6 -11 116 -11 113 0 142 2 119 10 -17 5 -36 19 -43
30 -23 37 -31 182 -31 564 l0 381 48 14 c26 8 84 13 132 12 135 -3 216 -41
272 -126 85 -132 18 -339 -122 -377 -48 -13 -96 -5 -167 27 -29 13 -65 23 -80
21 -23 -2 -29 -8 -31 -34 -3 -27 3 -37 39 -65 102 -82 136 -122 226 -259 139
-210 127 -200 242 -199 l96 0 -50 23 c-62 28 -106 76 -198 219 -81 124 -104
144 -237 205 -87 40 -117 63 -105 82 10 16 39 12 98 -15 154 -71 326 -19 395
118 35 70 33 172 -5 245 -33 65 -95 112 -177 136 -56 17 -200 19 -446 6z"/>
<path d="M2754 3094 c-5 -2 -66 -8 -135 -12 -75 -5 -116 -11 -104 -16 82 -31
80 -19 80 -491 0 -375 -2 -413 -18 -446 -12 -25 -28 -39 -50 -46 -26 -7 2 -12
138 -22 258 -20 323 -16 415 27 46 21 54 28 114 87 98 96 141 220 140 405 0
109 -4 135 -28 205 -74 217 -216 316 -451 314 -49 0 -95 -3 -101 -5z m238 -31
c24 -9 48 -22 54 -27 7 -6 24 -20 40 -31 38 -27 98 -123 124 -198 32 -90 40
-306 15 -409 -24 -98 -66 -179 -124 -238 -77 -78 -154 -102 -289 -88 -136 13
-122 -46 -122 503 0 349 3 476 12 481 44 28 227 32 290 7z"/>
<path d="M3623 2938 c2 -7 15 -12 30 -10 37 5 48 -34 17 -58 -16 -12 -19 -19
-10 -25 11 -7 110 79 110 97 0 4 -34 8 -76 8 -56 0 -75 -3 -71 -12z m99 -20
c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z"/>
<path d="M3803 2759 c41 -24 0 -49 -87 -54 -5 0 -11 -6 -14 -14 -5 -14 1 -14
102 6 25 5 28 4 22 -15 -4 -11 -2 -23 4 -27 13 -8 15 22 5 75 -2 8 -4 21 -4
28 -1 6 -11 12 -23 12 -21 -1 -21 -2 -5 -11z"/>
<path d="M3732 2528 c3 -7 14 -14 26 -16 31 -5 32 -62 2 -62 -21 0 -28 -24
-10 -35 6 -3 10 -2 10 3 0 5 27 21 61 35 33 14 58 31 55 36 -4 5 -31 15 -62
22 -30 7 -57 16 -60 21 -8 13 -26 9 -22 -4z m93 -44 c0 -5 -8 -10 -17 -12 -11
-2 -18 3 -18 11 0 9 8 14 18 13 9 -2 17 -7 17 -12z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
